var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-user-table" },
    [
      _c(
        "v-container",
        { staticClass: "mt-10" },
        [
          _c(
            "v-row",
            [
              _vm.successMessageLogs !== "" && _vm.loading === false
                ? _c(
                    "v-col",
                    {
                      staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-success"
                    },
                    [_vm._v(" " + _vm._s(_vm.successMessageLogs) + " ")]
                  )
                : _vm._e(),
              _vm.errorMessageLogs !== "" && _vm.loading === false
                ? _c(
                    "v-col",
                    { staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-error" },
                    [_vm._v(" " + _vm._s(_vm.errorMessageLogs) + " ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "pa-6 pr-10 mb-5" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text-subtitle-2 border-left-blue font-weight-bold"
                    },
                    [_vm._v(" 登録情報 ")]
                  )
                ],
                1
              ),
              _c("info-item", {
                attrs: { title: "会員ID", text: _vm.user.userId }
              }),
              _c("info-item", {
                attrs: { title: "氏名", text: _vm.user.name }
              }),
              _c("info-item", {
                attrs: { title: "フリガナ", text: _vm.user.furigana }
              }),
              _c("info-item", {
                attrs: {
                  title: "SMS認証",
                  text: _vm.user.isMfaSms === true ? "有効" : "無効"
                }
              }),
              _c("info-item", {
                attrs: { title: "電話番号", text: _vm.user.phoneNumber }
              }),
              _c("info-item", {
                attrs: { title: "メールアドレス", text: _vm.user.email }
              }),
              _c("info-item", {
                attrs: { title: "ログインパスワード", text: "********" }
              }),
              _c("info-item", {
                attrs: { title: "所属", text: _vm.user.company }
              }),
              _c("info-item", {
                attrs: {
                  title: "興味関心カテゴリ",
                  text: _vm.convertCommaJP(_vm.user.groupLoadIds)
                }
              }),
              _c("info-item", {
                attrs: { title: "興味関心 詳細", text: _vm.user.detail }
              }),
              _c("info-item", {
                attrs: {
                  title: "保有スキル",
                  text: _vm.convertCommaJP(_vm.user.skillIds)
                }
              }),
              _c("info-item", {
                attrs: { title: "保有スキル その他", text: _vm.user.otherSkill }
              }),
              _c("info-item", {
                attrs: {
                  title: "NTT西日本グループ社員",
                  text: _vm.user.isNttEmployee ? "はい" : "いいえ"
                }
              }),
              _c("info-item", {
                attrs: {
                  title: "メールでの案内を希望する",
                  text: _vm.user.isSendEmail ? "希望する" : "希望しない"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }