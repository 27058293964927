















import Vue from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import UserDetailTemplate from '@/components/templates/UserDetailTemplate/UserDetailTemplate.vue';
import * as Config from '@/config';
import { User} from '@/types/alligate';

export interface DataType {
  // Props
  page: number;
  loading: boolean;
  tempGroupPassages: any;
  cardItems: any;
  phoneItems: any;
  errorMessageLogs: string;
  listphones: any;
  listGatePassages: any;
  userData: any;
  goBack: string;
  skillOptions: any;
  gateGroupOptions: any;

}

export default Vue.extend({
  name: 'UserConfirmPage',
  components: {
    Breadcrumbs,
    SuccessButton,
    ActionButton,
    UserDetailTemplate,
  },
  mounted() {
    console.log('this.$route.query', this.$route.query);
    const userId = localStorage.userProfileId;
    if (userId !== null && userId !== undefined && userId !== '') {
      this.getInFoUser(userId);
    }
    if (this.$route.query.goBack !== null && this.$route.query.goBack !== undefined) {
      this.goBack = this.$route.query.goBack.toString();
    }
    this.$emit('activeMenu', 'user');
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  data: (): DataType => ({
    // Props
    loading: false,
    page: 1,
    tempGroupPassages: [],
    cardItems: [],
    phoneItems: [],
    errorMessageLogs: '',
    listphones: [],
    listGatePassages: [],
    userData: {
      isAdmin: false,
      isMfaSms: false,
      userId: '',
      email: '',
      password: '',
      name: '',
      furigana: '',
      phoneNumber: '',
      option1: '',
      option2: '',
      company: '',
      position: '',
      detail: '',
      checkAgreement: false,
      checkInformation: false,
      emailConfirm: '',
      term: '',
      info: '',
      otherSkill: '',
      skill: '',
      isCSV: false,
      skillIds: [],
      groupLoadIds: [],
    },
    gateGroupOptions: [
      {
        text : '地域創生',
        value : '1',
      },
      {
        text : 'ダイバーシティ',
        value : '2',
      },
      {
        text : '教育',
        value : '3',
      },
      {
        text : 'インフラ・エネルギー',
        value : '4',
      },
      {
        text : 'テクノロジー',
        value : '5',
      },
      {
        text : 'DX',
        value : '6',
      },
      {
        text : '金融',
        value : '7',
      },
      {
        text : 'フード',
        value : '8',
      },
      {
        text : 'ヘルス・メディカル',
        value : '9',
      },
      {
        text : 'ツーリズム＆モビリティ',
        value : '10',
      },
      {
        text : 'ライフスタイル',
        value : '11',
      },
      {
        text : 'アセット',
        value : '12',
      },
      {
        text : 'エンタメ',
        value : '13',
      },
      {
        text : 'スポーツ',
        value : '14',
      },
    ],
    skillOptions: [
      {
        text : 'IT/デジタル',
        value : '1',
      },
      {
        text : 'マーケティング',
        value : '2',
      },
      {
        text : '人材育成',
        value : '3',
      },
      {
        text : '経理',
        value : '4',
      },
      {
        text : '法律',
        value : '5',
      },
      {
        text : 'デザイン・アート',
        value : '6',
      },
      {
        text : 'エンタメ',
        value : '7',
      },
      {
        text : '営業',
        value : '8',
      },
      {
        text : '事業開発',
        value : '9',
      },
      {
        text : '接客サービス',
        value : '10',
      },
      {
        text : '建築/土木関係/研究開発',
        value : '11',
      },
    ],
    goBack: 'user',
  }),
  methods: {
    getInFoUser(userId: any) {
      this.loading = true;
      this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(userId))
        .then((response: any) => {
          this.userData = response.body.users[0];
          this.userData.phoneNumber = this.userData.phoneNumber.replace('+81', '0');
          const option = this.userData.option1.replaceAll('<', '').split('>');
          this.userData.birthDay = option[0] !== undefined ? option[0] : '';
          this.userData.company = option[1] !== undefined ? option[1] : '';
          this.userData.groupIds = option[2] !== undefined ? option[2] : '';
          this.userData.detail = option[3] !== undefined ? option[3] : '';
          this.userData.skill = option[4] !== undefined ? option[4] : '';
          this.userData.otherSkill = option[5] !== undefined ? option[5] : '';
          this.userData.isSendEmail = option[6] === '1' ? true : false;
          this.userData.isNttEmployee = option[9] === '1' ? true : false;
          this.userData.isCSV = option[7] === '1' ? true : false;
          this.userData.skillIds = [];
          this.userData.groupLoadIds = [];
          if (this.userData.skill !== '') {
            const skillIds = this.userData.skill.split('、');
            this.skillOptions.forEach((element: any, key: any) => {
              if (skillIds.includes(element.value)) {
                const index  = this.userData.skillIds.map((e: { value: any; }) => e.value).indexOf(element.value);
                if (index < 0) {
                  this.userData.skillIds.push({text: element.text, value: element.value});
                }
              }
            });
          }
          if (this.userData.groupLoadIds !== '') {
            const groupIds = this.userData.groupIds.split('、');
            this.gateGroupOptions.forEach((element: any, key: any) => {
              if (groupIds.includes(element.value)) {
                const index  = this.userData.groupLoadIds.map((e: { value: any; }) => e.value).indexOf(element.value);
                if (index < 0) {
                  this.userData.groupLoadIds.push({text: element.text, value: element.value});
                }
              }
            });
          }
          this.loading = false;
        })
        .then(null, (error: any) => {
          switch (error.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (error.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          this.loading = false;
        });
    },
  },
});
