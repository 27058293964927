import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import * as Config from '@/config';
import Store from '@/store';
import LoginPage from '@/components/pages/login/LoginPage.vue';
import LayoutTemplate from '../components/pages/layout/LayoutPage.vue';
import UserAddPage from '@/components/pages/user/UserAddPage.vue';
import UserDetail from '@/components/pages/user/UserDetailPage.vue';
import UserConfirm from '@/components/pages/user/UserConfirmPage.vue';
// @ts-ignore
import jwtDecode from 'jwt-decode';

Vue.use(VueRouter);

declare global {
  interface Window {
    WebAppInterface: any;
  }
}

const routes: RouteConfig[] = [
  {
    path: Config.LOGIN,
    name: 'login',
    component: LoginPage,
    beforeEnter: (to, from, next) => {
      if (!Store.state.auth.isLoggedIn) {
        // まだログイン場合
        next();
      } else {
        next({
          // ログインした場合
          path: Config.CONFIRM,
        });
      }
    },
  },
  {
    path: '/',
    component: LayoutTemplate,
    children: [
      {
        path: '/',
        name: 'Home',
        component: UserConfirm,
        beforeEnter: requireAuthRole,
      },
      {
        path: '/confirm',
        name: 'Home',
        component: UserConfirm,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.USER_DETAIL,
        name: 'UserDetail',
        component: UserDetail,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.USER_ADD,
        name: 'UserAdd',
        component: UserAddPage,
      },
      {
        path: Config.USER_EDIT,
        name: 'UserEdit',
        component: UserAddPage,
        beforeEnter: requireAuthRole,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

function requireAuth(to: any, from: any, next: any) {
  if (!Store.state.auth.isLoggedIn) {
    next({
      // まだログイン場合
      path: Config.LOGIN,
    });
  } else {
    next();
  }
}

function requireAuthRole(to: any, from: any, next: any) {
  if (window.WebAppInterface !== undefined || window.webkit !== undefined) {
    requestTokenMobile().then(() => {
      next();
    });
  } else {
    if (!Store.state.auth.isLoggedIn) {
      next({
        // まだログイン場合
        path: Config.LOGIN,
      });
    } else {
      next();
    }
  }
}
function _setAuthorToken(idToken: string) {
    const authStore = Store.state.auth;
    const userStore = Store.state.user;
    authStore.isLoggedIn = true;
    authStore.isLoginChallenge = false;
    authStore.accessToken = idToken;
    Store.commit('UPDATE_AUTH', authStore);
    Store.commit('UPDATE_USER', userStore);
    const tk = jwtDecode(idToken);
    localStorage.setItem('userProfileId', tk.alligate_user_id);
}

function requestTokenMobile() {
  return new Promise (async (resolve, reject) => {
    let token;
    if (window.WebAppInterface !== undefined) {
      token = await window.WebAppInterface.mobileRefreshToken();
    } else if (window.webkit !== undefined) {
      token = await window.webkit.messageHandlers.mobileRefreshToken.postMessage(null);
    }
    if (token === null) {
      resolve(false);
    } else {
      _setAuthorToken(token);
      resolve(true);
    }
  });
}

export default router;
